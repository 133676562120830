import { graphql, Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CharlesMeta from "components/common/meta/CharlesMeta";
import BlogLayout from "components/common/blog/BlogLayout";
import Container from "react-bootstrap/Container";
import PostList from "templates/Blog/common/PostList";
import { categorySlug } from "templates/Blog/utils/slugs";
import Breadcrumb, { categoryBreadcrumbs } from "templates/Blog/common/Breadcrumb";


import "./styles.sass";

class Category extends React.Component {
  render() {
    const { pageContext, data } = this.props;
    const { category, featuredPosts, recentPosts } = data;
    const {
      previousPagePath,
      nextPagePath,
      pageNumber,
      numberOfPages,
      humanPageNumber
    } = pageContext;

    return (
      <BlogLayout>
        <CharlesMeta
          title={category.name}
          description={category.name}
          canonicalPathname={categorySlug(category)}
        />
        <Container>
          <Breadcrumb links={categoryBreadcrumbs(category)} />
          <h1>{category.name}</h1>
          {
            pageNumber == 0 && featuredPosts.nodes.length > 0 && (
            <>
              <h2>Les plus lus</h2>
              <PostList posts={featuredPosts.nodes} />
            </>
            )
          }
          <h2>Articles</h2>
          <PostList posts={recentPosts.nodes} />
          { numberOfPages > 1 && (
            <Row className="blog-post-pages-link">
              <Col xs={6} md={4} className="text-left">
                {previousPagePath && (
                  <div className="blog-post-pages-link-prev">
                    <Link to={`${previousPagePath}/`}>{'<<'} Précédent</Link>
                  </div>
                )}
              </Col>
              <Col md={4} className="text-center d-none d-md-block">
                <div className="blog-post-pages-link-pages">
                  Page {humanPageNumber} / {numberOfPages}
                </div>
              </Col>
              <Col xs={6} md={4} className="text-right">
                {nextPagePath && (
                  <div className="blog-post-pages-link-next">
                    <Link to={`${nextPagePath}/`}>Suivant {'>>'}</Link>
                  </div>
                )}
              </Col>
            </Row>
          )}
          <div dangerouslySetInnerHTML={{ __html: category.description }} />
        </Container>
      </BlogLayout>
    );
  }
}

export default Category;

export const pageQuery = graphql`
  query BlogCategoryById($id: String!, $skip: Int!, $limit: Int!) {
    category: wpCategory(id: { eq: $id }) {
      id
      description
      name
      parent {
        id
      }
      slug
      posts {
        nodes {
          ...BlogPostThumbnail
        }
      }
    }
    recentPosts: allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
    featuredPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $id } } } },
        isSticky: { eq: true }
      }
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
  }
`;
