import React from 'react';
import { Layout } from "components/common";
import "./styles.sass";

const BlogLayout = ({ children, showLinksInFooter = false }) => (
  <Layout isBlog={true} showLinksInFooter={showLinksInFooter} bodyClass="body-without-intercom">
    <div className="blog-root">{children}</div>
  </Layout>
);

export default BlogLayout;
